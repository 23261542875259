<script setup lang="ts">
import {
  AccessLevel,
  type PermissionWithTeam,
  type Team,
} from '@respell/database';

const modal = useModal();
const toast = useToast();
const spellStore = useSpellsStore();
const workspaceStore = useWorkspaceStore();
const spellIdParam = useRouteParams('spellId');

const { spellId: spellIdProp } = defineProps<{ spellId?: string }>();

const { teams, workspace } = storeToRefs(workspaceStore);
const { permissions } = storeToRefs(spellStore);
const { shareWithTeam, updatePermission, removePermission } = spellStore;

const spellId = computed(() => spellIdProp || spellIdParam.value);

await useAsyncData<PermissionWithTeam[]>(
  `spellPermissions/${spellId.value}`,
  () => spellStore.fetchPermissions(spellId.value as string),
  {
    watch: [spellId],
  },
);

const availableTeams = computed(() => {
  return teams.value?.filter(
    (team: Team) =>
      team.workspaceId === workspace.value?.id &&
      !permissions.value
        ?.map((p: PermissionWithTeam) => p.teamId)
        .includes(team.id),
  );
});

const availableTeamOptions = computed(() => {
  return availableTeams.value?.map((team) => [
    {
      label: team.name,
      key: team.id,
      click: () => shareWithTeam(spellId.value as string, team.id),
    },
  ]);
});
</script>
<template>
  <UModal>
    <UCard
      :ui="{ base: 'w-full', footer: { base: 'flex justify-between gap-2' } }"
    >
      <template #header>
        <span class="flex justify-between">
          <p class="title">Share Spell</p>
          <UButton
            :padded="false"
            color="gray"
            variant="link"
            icon="i-ph-x"
            @click="modal.close"
          />
        </span>
      </template>

      <div class="flex flex-col items-start gap-4">
        <p class="body">Teams with access</p>
        <div
          v-for="permission in permissions"
          :key="permission.teamId"
          class="flex justify-between w-full"
        >
          <span class="flex gap-2">
            <UAvatar
              :alt="permission.team.name"
              size="lg"
              :ui="{
                rounded: 'rounded-md',
                background: 'bg-primary-100',
                placeholder: 'text-primary-500',
              }"
            />
            <p class="body font-semibold">{{ permission.team.name }}</p>
          </span>
          <UBadge
            v-if="permission.role === AccessLevel.owner"
            label="Owner"
            size="lg"
            color="gray"
            variant="soft"
          />
          <UDropdown
            v-else
            :items="[
              [
                {
                  label: 'Can View',
                  key: AccessLevel.viewer,
                  click: () =>
                    updatePermission(
                      spellId as string,
                      permission.id,
                      AccessLevel.viewer,
                    ),
                },
                {
                  label: 'Can Edit',
                  key: AccessLevel.editor,
                  click: () =>
                    updatePermission(
                      spellId as string,
                      permission.id,
                      AccessLevel.editor,
                    ),
                },
              ],
              [
                {
                  slot: 'remove',
                  label: 'Remove',
                  click: () =>
                    removePermission(spellId as string, permission.id),
                },
              ],
            ]"
          >
            <UButton
              :padded="false"
              size="lg"
              color="white"
              variant="link"
              trailing-icon="i-ph-caret-down"
              :label="
                permission.role === AccessLevel.viewer ? 'Can View' : 'Can Edit'
              "
            />
            <template #item="{ item }">
              <span class="truncate body-sm">{{ item.label }}</span>
              <UIcon
                v-if="item.key === permission.role"
                name="i-ph-check-circle-fill"
                class="flex-shrink-0 h-5 w-5 ml-auto text-primary-500"
              />
            </template>
            <template #remove="{ item }">
              <span class="truncate body-sm text-red-500">Remove</span>
              <UIcon
                name="i-ph-trash-simple"
                class="flex-shrink-0 h-5 w-5 ml-auto text-red-500"
              />
            </template>
          </UDropdown>
        </div>
        <div v-if="availableTeams.length" class="flex gap-2">
          <UDropdown :items="availableTeamOptions">
            <UButton
              icon="i-ph-plus"
              size="lg"
              color="gray"
              square
              variant="soft"
              class="border border-gray-200 border-dashed text-gray-400"
            />
            <template #item="{ item: team }">
              <span class="flex gap-3">
                <UAvatar
                  :alt="team.label"
                  size="xs"
                  :ui="{
                    rounded: 'rounded-md',
                    background: 'bg-primary-100',
                    placeholder: 'text-primary-500',
                  }"
                />
                <span class="truncate body-sm">{{ team.label }}</span>
              </span>
            </template>
          </UDropdown>
          <p class="body dimmed font-semibold">Add Team</p>
        </div>
      </div>
    </UCard>
  </UModal>
</template>
