<script setup lang="ts">
import { subject } from '@casl/ability';
import type { Spell } from '@respell/database';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import ShareModal from '~/components/modals/ShareModal.vue';
import { useAppAbility } from '~/composables/useAppAbility';

const { can } = useAppAbility();
const props = defineProps<{
  spell: Spell;
  isTriggered?: boolean;
}>();

const spellStore = useSpellsStore();
const modal = useModal();
const spellId = useRouteParams('spellId');
const teamId = useRouteParams('teamId');

const inSpellPage = computed(() => !!spellId.value);

const moreOptions = computed(() => {
  const baseOptions = [];

  if (can('edit', subject('Spell', props.spell))) {
    baseOptions.push({
      label: 'Settings',
      icon: 'i-ph-gear',
      color: 'black',
      click: () =>
        navigateTo(`/spell/${props.spell.id}/settings`, {
          replace: true,
        }),
    });
    if (props.spell.templateMetadata?.preview) {
      baseOptions.push({
        label: 'Template',
        icon: 'i-ph-eye',
        color: 'black',
        click: () =>
          navigateTo(`/spell/${props.spell.id}/preview`, {
            replace: true,
          }),
      });
    }
  }

  baseOptions.push({
    label: 'Duplicate',
    icon: 'i-ph-copy',
    color: 'black',
    click: () =>
      spellStore.duplicateSpell(props.spell.id, teamId.value as string),
  });

  if (!inSpellPage.value) {
    baseOptions.push({
      label: 'Share',
      icon: 'i-ph-user-plus',
      color: 'black',
      click: () => modal.open(ShareModal, { spellId: props.spell.id }),
    });
    if (!props.isTriggered) {
      baseOptions.splice(2, 0, {
        label: 'Insights',
        icon: 'i-ph-chart-line',
        color: 'black',
        click: () => navigateTo(`/spell/${props.spell.id}/history`),
      });
    }
  }

  if (can('delete', subject('Spell', props.spell))) {
    baseOptions.push({
      label: 'Delete',
      icon: 'i-ph-trash-simple',
      color: 'rose',
      click: handleDelete,
    });
  }

  return baseOptions;
});

async function handleDelete() {
  modal.open(ConfirmModal, {
    type: 'spell',
    action: 'delete',
    message: 'Are you sure you want to delete this spell?',
    isDangerous: true,
    async onConfirm() {
      await spellStore.deleteSpell(props.spell.id);
      modal.close();
      if (inSpellPage.value) await navigateTo('/');
    },
  });
}
</script>

<template>
  <div class="relative" @click.stop @mousedown.stop>
    <UDropdown
      :items="[moreOptions]"
      :ui="{
        item: { base: 'w-full' },
        container: 'z-10',
        base: 'shadow-lg rounded-md ring-1 ring-gray-200 dark:ring-gray-800',
      }"
      :popper="{ placement: 'bottom-end' }"
      mode="click"
    >
      <UTooltip text="More options" :popper="{ placement: 'top' }">
        <UButton
          icon="i-ph-dots-three-outline-vertical-fill"
          size="lg"
          :padded="false"
          color="gray"
          variant="link"
          @mousedown.stop
        />
      </UTooltip>

      <template #item="{ item }">
        <button
          class="w-full text-left flex items-center justify-start p-2 hover:bg-gray-100 dark:hover:bg-gray-700"
          @mousedown.stop
        >
          <UIcon
            :name="item.icon"
            class="flex-shrink-0 h-5 w-5 mr-3"
            :class="`text-${item.color}-500`"
          />
          <span
            class="truncate font-semibold"
            :class="{ [`text-${item.color}-500`]: true }"
            >{{ item.label }}</span
          >
        </button>
      </template>
    </UDropdown>
  </div>
</template>
